import React, { Component } from 'react';
// import Slider from "react-slick";

// import sc1 from '../img/sc_dashboard.jpeg';
// import sc2 from '../img/sc_new_blank.jpeg';
// import sc3 from '../img/sc_patient.jpeg';
// import sc4 from '../img/sc_schedule.jpeg';
import ReactPlayer from "react-player"
import AnchorLink from 'react-anchor-link-smooth-scroll';

// import vid from '../img/video.mov'

class Features extends Component {
  render() {
    const carouselSettings = {
      dots: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      variableWidth: true
    };

    return (
      <div class="bg-white py-20 " id="screenshots">
        <div class="container mx-auto lg:flex items-center">
          <div class="w-full lg:w-1/2 md:pr-0 px-10 md:px-20">
          <p class="text-indigo-500 uppercase tracking-widest">Our Mission</p>
            <p class="font-medium text-5xl text-gray-900 leading-none my-5" >What is PatientSmart?</p>
            <p class="text-2xl text-gray-500 md:mt-3">PatientSmart allows medical clinics to <span class="text-gray-600">automate appointment reminders for patients</span>. With our easy to use interface, sending notifications to patients has never been easier.</p>
            <div class="mt-8 w-full">
              <AnchorLink href="#contact" class="font-medium focus:outline-none bg-gray-900 hover:bg-black text-white py-4 px-5 text-lg shadow rounded-sm">
                Request a Demo
              </AnchorLink>
            </div>
          </div>
          <div class="w-full lg:w-1/2 flex items-center justify-center px-3">

            <ReactPlayer
              url="https://vimeo.com/427887949"
              controls={true}
            />
            {/* <div class="slider shadow-2xl my-5">
              <Slider {...carouselSettings}>
                <img src={sc1} class="max-w-sm carousel-sc" />
                <img src={sc2} class="max-w-sm carousel-sc" />
                <img src={sc3} class="max-w-sm carousel-sc" />
                <img src={sc4} class="max-w-sm carousel-sc" />
              </Slider>
            </div> */}
          </div>
        </div>
      </div>
    )
  }
}


export default Features;
