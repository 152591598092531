import React, { Component } from 'react';

import AnchorLink from 'react-anchor-link-smooth-scroll';
import hero from '../img/hero.png';

class HomeHero extends Component {
  render() {
    return (
      <div className="bg-hero px-10 md:px-32 h-screen items-center justify-center flex" id="hero">
        <div className="container mx-auto md:flex items-center justify-center">
          <div className="w-full md:w-1/2 mb-4 md:mb-0" data-aos="fade-up" data-aos-duration="2000">
            <div class="text-center md:text-left">
              <div>
                <p className="text-5xl md:text-6xl font-medium leading-none">PatientSmart</p>
                <p className="text-4xl md:text-5xl text-gray-600 font-thin leading-none mt-6">Be connected with your patients.</p>
                {/* <p className="text-gray-500 text-3xl mt-6">Enabling clinics to prevent more missed appointments by using text message reminders.</p> */}
              </div>
              <div class="mt-16 p">
                <AnchorLink class="button-hero rounded-sm bg-indigo-600 text-white py-5 px-8 text-lg focus:outline-none" href="#screenshots">
                  Learn More
                  <i class="fas fa-chevron-right text-white ml-3 font-thin text-sm" />
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 mt-10 md:mt-0" data-aos="fade-up" data-aos-duration="3000">
            <img src={hero} class="mx-auto max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg sm:max-w-md" />
          </div>
        </div>
      </div>
    )
  }
}


export default HomeHero;
