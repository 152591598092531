import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Features extends Component {
  render() {
    return (
      <div class="bg-white py-20 px-5">
        <div class="container mx-auto">
          <div class="text-center">
            <p class="text-5xl font-medium text-black">Our Numbers</p>
            <div className="flex justify-center">
              <p class="text-gray-500 text-3xl leading-tight mt-2 max-w-2xl center">Our lasting impact on the medical community, measured objectively.</p>
            </div>
            
          </div>

          {/* <div class="w-full lg:w-2/3 mx-auto mt-10">
            <div class="w-full mx-auto lg:flex mt-5 rounded shadow-2xl ">
              <div class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden bg-gray-200 bg-balboa" title="Woman holding a mug">
              </div>
              <div class="bg-white p-4 flex flex-col justify-between leading-normal">
                <div class="mb-8">
                  <a class="text-blue-700 hover:text-blue-900 font-bold text-xl" href="https://www.balboapediatrics.com/" target="_blank">Balboa Pediatric Clinic</a>
                  <p class="mt-1 text-gray-500 text-md">PatientSmart currently powers patient communication for Balboa Pediatric Clinic. Our clients are very happy with our services!</p>
                </div>
              </div>
            </div>


          </div> */}
          <div class=" max-w-xl mx-auto">
            <div class="md:justify-between md:-mx-4 md:flex mt-10">
              {/* <div class="w-full md:mx-4 text-center">
                <p class="uppercase font-bold text-gray-600 text-sm">Clinics</p>
                <p class="font-bold text-6xl text-indigo-500 leading-tight">01</p>
              </div> */}
              <div class="w-full md:mx-4 text-center md:mt-0 mt-8">
                <p class="uppercase text-lg font-bold text-gray-600 text-sm">Doctors</p>
                <p class="font-bold text-6xl text-indigo-600 leading-tight">9</p>
              </div>

              <div class="w-full md:mx-4 text-center md:mt-0 mt-8">
                <p class="uppercase text-lg font-bold text-gray-600 text-sm">Patients</p>
                <p class="font-bold text-6xl text-indigo-700 leading-tight">10,000+</p>
              </div>

              
            </div>
            
            
          </div>

          



        </div>
      </div>
    )
  }
}


export default Features;
