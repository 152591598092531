import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: "",
      last: "",
      email: "",
      message: "",
      sent: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleSubmit(event) {
    // event.submit()
    // event.preventDefault();

    toast.info('Message Sent', {
      position: "top-right"
    });

    // $(".contact-input").val("");
  }

  render() {
    return (
      <div className="bg-gray-100 py-10 md:py-24 px-2 md:px-10" id="contact">
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
        />

        <div className="container mx-auto h-full lg:flex justify-center items-center">
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0 px-4 md:pr-6 lg:pr-10">
            <div class="mx-auto">
              <p class="text-indigo-500 uppercase tracking-widest">Get In Touch</p>
              <p class="font-medium text-5xl text-black-500">Contact Us</p>
              <p class="text-gray-500 text-xl">Feel free to contact us about anything. If you're interested in our application and think that it can benefit your clinic, please message us. We also have a <span class="font-bold text-gray-700">free 2-week trial</span> for our application.</p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 mx-auto p-8 lg:p-12 bg-white shadow-2xl rounded-lg" data-aos="zoom-in-up" data-aos-duration="1000">
            <form onSubmit={this.handleSubmit} action="https://formspree.io/patientsmart@gmail.com" method="POST" id="contactForm" >
            {/* <form onSubmit={this.handleSubmit} name="contact" method="POST" data-netlify="true" id="contactForm" > */}
              <div class="md:flex -mx-2">
                <div class="w-full md:w-1/2 px-2">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 md:mb-2">
                    First Name
                  </label>
                  <input name="first" class="contact-input bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" placeholder="Jane" value={this.state.first} onChange={this.handleChange} />
                </div>

                <div class="w-full md:w-1/2 px-2">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 md:mb-2 mt-3 md:mt-0">
                    Last Name
                  </label>
                  <input name="last" class="contact-input bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" placeholder="Doe" value={this.state.last} onChange={this.handleChange} />
                </div>
              </div>

              <div class="w-full mt-4">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 md:mb-2">
                  Email Address
                </label>
                <input name="email" class="contact-input bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="email" placeholder="janedoe@gmail.com" value={this.state.email} onChange={this.handleChange} />
              </div>

              <div class="w-full mt-4">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 md:mb-2">
                  Your Message
                </label>
                <textarea name="message" class="contact-input bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" required type="text" placeholder="" value={this.state.message} rows="5" onChange={this.handleChange}></textarea>
              </div>

              <div class="w-full mt-4">
                <input class="bg-indigo-500 hover:bg-indigo-600 focus:outline-none text-white font-bold py-3 px-4 rounded w-full" value="Send Message" type="submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}


export default Contact;
