import React, { Component } from 'react';

class Features extends Component {
  render() {
    return (
      <div class="bg-gray-100 py-20 px-5 md:px-10">
        <div class="container mx-auto text-center">
          <div class="mb-10">
            <p class="font-medium text-5xl text-black">Key Features</p>
            <div className="flex justify-center">
              <p class="text-gray-500 text-3xl leading-tight mt-2 max-w-xl center">Just some of the reasons PatientSmart needs to be added at your clinic.</p>
            </div>
          </div>
          <div class="flex flex-wrap px-8 md:px-10 lg:px-20">
            <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3 mb-3">
              <div class="">
                <i class="icon fas fa-paper-plane text-6xl"></i>
                <p class="font-medium text-indigo-600 text-3xl">Notifications</p>
                <p class="text-base text-gray-500 mt-2">Just enter the appointment time, and let PatientSmart text your patients for you.</p>
              </div>
            </div>
            <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
              <div class="">
                <i class="icon fas fa-lock text-6xl"></i>
                <p class="font-medium text-indigo-600 text-3xl">Security</p>
                <p class="text-base text-gray-500 mt-2">PatientSmart prides itself in the highest level of security, and uses production-level Google Firebase servers to store patient data.</p>
              </div>
            </div>
            <div class="sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
              <div class="">
                <i class="icon fas fa-user-plus text-6xl"></i>
                <p class="font-medium text-indigo-600 text-3xl"> New Patients</p>
                <p class="text-base text-gray-500 mt-2">Adding a patient is as easy as a name, phone number and birthday.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default Features;
