import React, { Component } from 'react';

import HomeHero from './HomeHero';
import Contact from './Contact';
import Features from './Features';
import Screenshots from './Screenshots';
import Clients from './Clients';


class HomePage extends Component {
  render() {
    

    return (
      <div>
        <HomeHero />

        <Screenshots />

        <Features />

        <Clients />
        
        <Contact />
      </div>
    )
  }
}


export default HomePage;
