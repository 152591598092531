import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import HomePage from './components/HomePage';

ReactGA.initialize('UA-142755611-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>

      <Navbar />

      <Route
        exact
        path="/"
        render={(props) =>
          <HomePage {...props} />
        } />

      <Footer />

    </Router>
  );
}

export default App;
