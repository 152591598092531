import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import logo from '../img/logo.png';

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      scrolling: false,
      responsiveNavbar: false
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.responsiveNavbar = this.responsiveNavbar.bind(this);
  }

  handleScroll(event) {
    if (window.scrollY === 0 && this.state.scrolling == true) {
      this.setState({ scrolling: false });
    }
    else if (window.scrollY !== 0 && this.state.scrolling != true) {
      this.setState({ scrolling: true });
    }
  }

  responsiveNavbar() {
    this.setState({
      responsiveNavbar: !this.state.responsiveNavbar
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }


  render() {
    return (
      <nav id="header" class={"fixed w-full z-30 top-0 transition" + (this.state.scrolling ? " bg-white border-b" : " ")} >
        <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">

          <div class="pl-4 flex items-center">
            <a class="no-underline hover:no-underline font-medium text-xl" href="/">
              <img src={logo} class="h-12 fill-current inline" />
            </a>
          </div>

          <div class="block lg:hidden pr-4">
            <button onClick={this.responsiveNavbar} id="nav-toggle" class="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none">
              <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
            </button>
          </div>

          <div class={"w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20 " + (this.state.responsiveNavbar ? "" : "hidden")} id="nav-content">
            <ul class="list-reset lg:flex justify-end flex-1 items-center">
              <li class="mr-3">
                <AnchorLink onClick={this.responsiveNavbar} class="inline-block no-underline hover:black hover:border-b text-gray-600 font-semibold tracking-wide py-2 px-4" href="#hero">Home</AnchorLink>
              </li>
              <li class="mr-3">
                <AnchorLink onClick={this.responsiveNavbar} class="inline-block no-underline hover:black hover:border-b text-gray-600 font-semibold tracking-wide py-2 px-4" href="#contact">Contact</AnchorLink>
              </li>
            </ul>
            {/* <button id="navAction" class="cursor-default mx-auto lg:mx-0 hover:shadow-md bg-white text-gray-800 font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow cursor-arrow focus:outline-none">Log In</button> */}
          </div>
        </div>

        <hr class="border-b border-gray-100 opacity-25 my-0 py-0" />
      </nav>

    );
  }
}

export default Navbar;
