import React, { Component } from 'react';
import logo from '../img/logo.png';

class Footer extends Component {
  render() {
    return (
      <div>
        <div class="bg-gray-200 py-16">
          <div class="text-center">
            <p class="text-gray-500 text-xl font-semibold text-sm">
              &copy; PatientSmart 2022
            </p>
          </div>
        </div>
      </div>

    );
  }
}

export default Footer;
